import React, {useEffect, useState} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {trackPromise} from "react-promise-tracker";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {
    addTotals,
    buildColumnsFromData,
    buildComparativeData,
    buildRangeParameters,
    convertToMomentTz,
    filterByStrategy,
    formatNumbersDecimals,
    getDateFormatByAggregation,
    sortTechnologies
} from "../commons";
import ChartTableToggle from "../common/ChartTableToggle";
import BasicTable from "../common/BasicTable";
import LineChartWrapper from "../common/LineChartWrapper";

function ErrorFallback({error}) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    )
}

function CapturedPriceOutput({simulationId, aggregation, range, compareWith,
    groupBy, strategy, compareWithSeries}) {

    const [prices, setPrices] = useState([]);
    const [compareSimulations, setCompareSimulations] = useState([]);
    const [tabMode, setTabMode] = useState("charts");
    const [tabSelected, setTabSelected] = useState(1);
    const [compareSimulationsBySeries, setCompareSimulationsBySeries] = useState([]);

    useEffect(() => {
        if(groupBy !== 'Hour') {
            trackPromise(
                fetch('/m40alasocho/simulation/' + simulationId + '/results/capturedPrice' + aggregation + '?' + buildRangeParameters(range) + '&aggregation=' + groupBy))
                .then(res => res.json()).then(data => {
                setPrices(data["data"]);
            });
        }else{
            setPrices([]);
        }
    }, [simulationId, aggregation, range, groupBy]);

    useEffect(() => {
        if(groupBy !== 'Hour') {
            if (compareWith.length > 0) {
                compareWith.forEach(c => {
                    if (compareSimulations.filter(cs => cs["simId"] === c).length === 0) {
                        trackPromise(
                            fetch('/m40alasocho/simulation/' + c + '/results/capturedPrice' + aggregation + '?' + buildRangeParameters(range) + '&aggregation=' + groupBy))
                            .then(res => res.json()).then(data => {
                            setCompareSimulations(comp => [...comp, {
                                "data": [...data["data"]],
                                "simId": c
                            }
                            ]);
                        });
                    }
                });
                setCompareSimulations(cs => cs.filter(c => compareWith.includes(c["simId"])));
            } else {
                setCompareSimulations([]);
            }
        }
        // eslint-disable-next-line
    }, [aggregation, range, compareWith, groupBy]);

    useEffect(()=>{
        if(compareWithSeries.length > 0 && groupBy !== 'Hour') {
            compareWithSeries.forEach(c=>{
                trackPromise(
                    fetch('/m40alasocho/simulation/' + simulationId + '/data/' + c + '/price/compare?'
                        + buildRangeParameters(range) + '&aggregation=' + groupBy + '&allData=1'))
                    .then(res => res.json()).then(data => {
                    if(compareSimulationsBySeries.filter(cs=>cs["ds"]===c).length === 0) {
                        setCompareSimulationsBySeries(comp => [...comp, {
                            "data": [...data["data"].map(d=>{
                                if(groupBy === 'All'){
                                    return d;
                                }
                                return {
                                    "time": d.time,
                                    "DS-price-": d.maxPrice
                                }
                            })],
                            "ds": c
                        }]);
                    }else{
                        setCompareSimulationsBySeries(comp => comp.map(cs=>{
                            if(cs["ds"] !== c){
                                return cs;
                            }else{
                                return {
                                    ...cs,
                                    "data": [...data["data"].map(d=>{
                                        if(groupBy === 'All'){
                                            return d;
                                        }
                                        return {
                                            "time": d.time,
                                            "DS-price-": d.maxPrice
                                        }
                                    })],
                                }
                            }
                        }));
                    }
                });
            });
        }else{
            setCompareSimulationsBySeries([]);
        }
        // eslint-disable-next-line
    }, [range, aggregation, compareWithSeries, groupBy]);

    let byStrategy = prices.filter(g=>g["strategy"]).map(g=> {
        if(groupBy !== 'All' && "data" in g) {
            const data = sortTechnologies(JSON.parse(g["data"]), aggregation);
            return {
                time: g["entryDate"],
                data
            }
        }else{
            let out = {
                ...g
            }
            delete out.strategy;
            return  sortTechnologies(out, aggregation);
        }
    });
    let byCompetitive = prices.filter(g=>g["strategy"] === false).map(g=> {
        if(groupBy !== 'All' && "data" in g) {
            const data = sortTechnologies(JSON.parse(g["data"]), aggregation);
            return {
                time: g["entryDate"],
                data
            }
        }else{
            let out = {
                ...g
            }
            delete out.strategy;
            return sortTechnologies(out, aggregation);
        }
    });

    const chartDataStrategic = compareSimulations.length > 0 || compareSimulationsBySeries.length > 0
        ? buildComparativeData(filterByStrategy(compareSimulations, true).concat(compareSimulationsBySeries), byStrategy)
        : byStrategy;
    const chartDataCompetitive = compareSimulations.length > 0 || compareSimulationsBySeries.length > 0
        ? buildComparativeData(filterByStrategy(compareSimulations, false).concat(compareSimulationsBySeries), byCompetitive)
        : byCompetitive;

    const dataTableComp = formatNumbersDecimals([...chartDataCompetitive].map(d=>({...d,
                                                            "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))})));

    const competitiveTable =
            <BasicTable title={"Captured Price By " + aggregation + " (Competitive) (MWh)"} data={addTotals(dataTableComp)} columns={buildColumnsFromData(chartDataCompetitive)} options={{
                fixedHeaderScrollHeight: "350px"
            }}/>
    ;

    const dataTableStrategic =formatNumbersDecimals([...chartDataStrategic].map(d=>({...d, "time": convertToMomentTz(d.time).format(getDateFormatByAggregation(groupBy))})));
    const strategicTable = 
        <BasicTable title={"Captured Price By " + aggregation + " (Strategic) (MWh)"} data={addTotals(dataTableStrategic)} columns={buildColumnsFromData(chartDataStrategic)} options={{
            fixedHeaderScrollHeight: "350px"
        }}/>;

    let tabStrategicVsCompetitive = null;
    if(['Year', 'All'].includes(groupBy) && strategy && chartDataStrategic.length > 0){
        let aggregatedObject = {}
        Object.keys(chartDataStrategic[0]).forEach(k => {
            if(k !== "time") {
                aggregatedObject[k + '[S]'] = chartDataStrategic[0][k];
            }
        });
        Object.keys(chartDataCompetitive[0]).forEach(k => {
            if(k !== "time") {
                aggregatedObject[k + '[C]'] = chartDataCompetitive[0][k];
            }
        });

        tabStrategicVsCompetitive = <Tab eventKey={5} title="Competitive vs Strategic">
                                        <LineChartWrapper data={[aggregatedObject]}
                                                          aggregation={groupBy}
                                                          title={'Strategic vs Competitive'}
                                                          brushKey="time"
                                                          xAxisKey="index"
                                                          noDataMessage={"This chart is not available for the hourly aggregation"}
                                                          yAxisUnit="€/MWh" />
                                    </Tab>
    }

    const tabsByMode = tabMode === "charts" ? (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive) ">
                <LineChartWrapper data={chartDataCompetitive}
                                  title={'Captured Price By ' + aggregation + ' (Competitive)'}
                                  brushKey="time"
                                  xAxisKey="time"
                                  yAxisUnit="€/MWh"
                                  noDataMessage={"This chart is not available for the hourly aggregation"}
                                  aggregation={groupBy}/>
            </Tab>
            <Tab eventKey={2} title="Duration (Competitive)">
                <LineChartWrapper data={chartDataCompetitive}
                                  aggregation={groupBy}
                                  noDataMessage={"This chart is not available for the hourly aggregation"}
                                  title={'Captured Price By ' + aggregation + ' (Competitive)'}
                                  brushKey="time"
                                  xAxisKey="index"
                                  yAxisUnit="€/MWh" />
            </Tab>
            {strategy &&
                <Tab eventKey={3} title="Chronological (Strategic) ">
                    <LineChartWrapper data={chartDataStrategic}
                                      aggregation={groupBy}
                                      noDataMessage={"This chart is not available for the hourly aggregation"}
                                      title={'Captured Price By ' + aggregation
                                          + ' (Strategic)'}
                                      brushKey="time" xAxisKey="time"
                                      yAxisUnit="€/MWh"/>
                </Tab>
            }
            {strategy &&
                <Tab eventKey={4} title="Duration (Strategic)">
                    <LineChartWrapper data={chartDataStrategic}
                                      aggregation={groupBy}
                                      title={'Captured Price By ' + aggregation + ' (Strategic)'}
                                      brushKey="time"
                                      xAxisKey="index"
                                      noDataMessage={"This chart is not available for the hourly aggregation"}
                                      yAxisUnit="€/MWh" />
                </Tab>
            }
            {tabStrategicVsCompetitive}
        </Tabs>
    ) : (
        <Tabs defaultActiveKey={tabSelected} activeKey={tabSelected} id="uncontrolled-tab-example" transition={false} onSelect={(tab)=>{
            setTabSelected(tab)
        }}>
            <Tab eventKey={1} title="Chronological (Competitive) ">
                {competitiveTable}
            </Tab>
            {strategy &&
                <Tab eventKey={2} title="Chronological (Strategic) ">
                    {strategicTable}
                </Tab>
            }
        </Tabs>
    );


    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <div className="recharts-component">
                <ChartTableToggle mode={tabMode} updateSelectedTab={setTabSelected} updateTabMode={setTabMode}/>
                <Row>
                    <Col sm={12}>
                        {tabsByMode}
                    </Col>
                </Row>
            </div>
        </ErrorBoundary>
    );
}

export default CapturedPriceOutput;
